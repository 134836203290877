import React from 'react';
import Layout from '../components/layout';

import Blog from '../components/blog';
import Contact from '../components/contact';
import Footer from '../components/footer';

const OurStoryPage = () => (
    <Layout>
        <Blog></Blog>
        <Contact></Contact>
        <Footer></Footer>
    </Layout>
)

export default OurStoryPage
