import React from "react";

import styles from "./ourStoryFull.module.css";
import family from "../images/IMG_4900.jpg";

const Blog = () => (
  <div className={styles.container}>
    <img src={family} />
    <h1>Coming Soon!</h1>
  </div>
);

export default Blog;
